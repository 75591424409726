"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_final_form_1 = require("react-final-form");
const components_1 = require("components");
const utils_1 = require("utils");
function InputDate({ fieldName, dataTestId, fieldLabel }) {
    return ((0, jsx_runtime_1.jsx)(react_final_form_1.Field, { id: fieldName, name: fieldName, type: "text", "data-testid": dataTestId, placeholder: "MM/DD/YYYY", validate: (0, utils_1.composeValidators)(utils_1.validateRequiredField, (0, utils_1.validateLength)(10, 10, 'Expected format should be mm/dd/yyyy')), parse: utils_1.formatDateFromInputString, children: ({ input, meta }) => ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [' ', (0, jsx_runtime_1.jsx)(components_1.Label, { size: "medium", htmlFor: fieldName, className: meta.error && meta.touched ? 'invalid' : '', children: fieldLabel }), (0, jsx_runtime_1.jsx)("input", { ...input, id: fieldName, className: meta.error && meta.touched ? 'invalid' : '', placeholder: "mm/dd/yyyy" }), meta.error && meta.touched ? ((0, jsx_runtime_1.jsx)("span", { className: "error", children: meta.error })) : ((0, jsx_runtime_1.jsx)("span", {}))] })) }));
}
exports.default = InputDate;
