"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const lp_components_1 = require("lp-components");
const components_1 = require("components");
const forms_1 = require("../forms");
function LoanCancelConfirmationModal({ handleClose, handleConfirm, handleSubmitSuccess, handleSubmitFailure, }) {
    return ((0, jsx_runtime_1.jsx)(lp_components_1.Modal, { onClose: () => {
            handleClose();
        }, hideCloseButton: true, className: "modal-inner prequal-decline-modal modal", children: (0, jsx_runtime_1.jsxs)("div", { className: "sales-confirmation", children: [(0, jsx_runtime_1.jsxs)("div", { className: "sales-confirmation__container", children: [(0, jsx_runtime_1.jsx)(components_1.H3, { variant: "headline-small", className: "title", children: "Cancel Loan" }), (0, jsx_runtime_1.jsx)("table", { className: "details m-t-20", children: (0, jsx_runtime_1.jsx)("tr", { children: (0, jsx_runtime_1.jsx)("td", { children: (0, jsx_runtime_1.jsx)(components_1.P, { variant: "body-large", children: "Please confirm the cancelation request. Once submitted, the patient must reapply order to pay for their service." }) }) }) })] }), (0, jsx_runtime_1.jsx)(forms_1.LoanAdjustmentConfirmationForm, { onSubmit: handleConfirm, onSubmitSuccess: handleSubmitSuccess, onSubmitFail: handleSubmitFailure, handleClose: handleClose })] }) }));
}
exports.default = LoanCancelConfirmationModal;
