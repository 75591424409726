"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const components_1 = require("components");
function ProgramTabs({ currentProgramShown, setCurrentProgramShown, }) {
    return ((0, jsx_runtime_1.jsxs)("div", { className: "program-tabs", children: [(0, jsx_runtime_1.jsxs)("div", { className: "program-tab", onClick: () => setCurrentProgramShown('ac-card'), role: "button", tabIndex: 0, onKeyDown: (e) => {
                    if (e.key === 'Enter') {
                        setCurrentProgramShown('ac-card');
                    }
                }, children: [(0, jsx_runtime_1.jsx)(components_1.H2, { variant: "headline-medium", className: currentProgramShown !== 'ac-card' ? 'inactive-tab' : '', children: "Alphaeon Credit Card" }), (0, jsx_runtime_1.jsx)("div", { "data-testid": "ac-card-underline", className: currentProgramShown === 'ac-card'
                            ? 'active-tab-underline'
                            : 'inactive-tab-underline' })] }), (0, jsx_runtime_1.jsxs)("div", { className: "program-tab", onClick: () => setCurrentProgramShown('access'), role: "button", tabIndex: 0, onKeyDown: (e) => {
                    if (e.key === 'Enter') {
                        setCurrentProgramShown('access');
                    }
                }, children: [(0, jsx_runtime_1.jsx)(components_1.H2, { variant: "headline-medium", className: currentProgramShown !== 'access' ? 'inactive-tab' : '', children: "Access Program" }), (0, jsx_runtime_1.jsx)("div", { "data-testid": "access-program-underline", className: currentProgramShown === 'access'
                            ? 'active-tab-underline'
                            : 'inactive-tab-underline' })] })] }));
}
exports.default = ProgramTabs;
