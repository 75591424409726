"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_cookie_1 = require("react-cookie");
const react_redux_1 = require("react-redux");
const react_router_dom_1 = require("react-router-dom");
const redux_1 = require("redux");
const redux_form_1 = require("redux-form");
const ab_tests_api_1 = require("ab-tests-api");
const apiActions = __importStar(require("api-actions"));
const components_1 = require("components");
const config_1 = require("config");
const actions = __importStar(require("../actions"));
const reducer_1 = require("../reducer");
const utils_1 = require("../utils");
function PracticeSearchInput({ setPracticesList, className = '', isSubmitting, practicesList, searchPromptText, withBackButton = false, setPractice, setPracticeLocations, clearPracticeLocations, getPracticeLocations, practiceLocations, }) {
    // Hooks
    const history = (0, react_router_dom_1.useHistory)();
    const { search } = (0, react_router_dom_1.useLocation)();
    const [cookies, setCookie] = (0, react_cookie_1.useCookies)(['proveAb', 'proveStoreAb']);
    const [assignProveVariant] = (0, ab_tests_api_1.usePostAbTestsV1ParticipantsAssignVariantTestSlugMutation)();
    // Vars
    const query = new URLSearchParams(search);
    const store_number = query.get('store_number');
    (0, react_1.useEffect)(() => {
        if (store_number) {
            handleSubmit({ adsStoreNumber: store_number });
        }
    }, [store_number]);
    const handleSubmit = async (item) => {
        if (item && item.locationId) {
            setPractice(item);
            const testGroup = await (0, utils_1.assignProveAbVariant)(cookies, assignProveVariant, setCookie, 'consumer_prove_yes_store_test', 'yes_store', item.locationId);
            if (testGroup === 'treatment') {
                history.push({
                    pathname: '/credit-portal/prove',
                    search: `?store=${item.adsStoreNumber}`,
                    state: {
                        practice: item,
                    },
                });
            }
            else {
                history.push({
                    pathname: (0, config_1.makeStoreUrl)(Number(item.adsStoreNumber)),
                    state: {
                        practice: item,
                    },
                });
            }
        }
        else {
            item && item.preventDefault && item.preventDefault();
            return false;
        }
    };
    const handleSubmitSuccess = () => {
        history.push(config_1.PRACTICE_URL);
    };
    const handleSubmitFailure = () => {
        setPracticesList(null);
        history.push(config_1.PRACTICE_URL);
    };
    return ((0, jsx_runtime_1.jsxs)("div", { className: `practice-search-input ${className} `, children: [(0, jsx_runtime_1.jsx)(components_1.PracticeSearchForm, { initialValues: {
                    adsStoreNumber: store_number,
                }, clearPracticeLocations: clearPracticeLocations, getPracticeLocations: getPracticeLocations, setPracticeLocations: setPracticeLocations, onSubmit: handleSubmit, onSubmitSuccess: handleSubmitSuccess, onSubmitFail: handleSubmitFailure, practicesList: practicesList, searchPromptText: searchPromptText, withBackButton: withBackButton, handleSubmit: handleSubmit, practiceLocations: practiceLocations }), (0, jsx_runtime_1.jsx)("div", { className: "mt-5 spinner", children: isSubmitting && (0, jsx_runtime_1.jsx)(components_1.Spinner, {}) })] }));
}
function mapStateToProps(state) {
    return {
        isSubmitting: (0, redux_form_1.isSubmitting)('practiceSearchForm')(state),
        practicesList: reducer_1.selectors.practicesList(state),
        practiceLocations: reducer_1.selectors.practiceLocations(state),
    };
}
const mapDispatchToProps = {
    setPracticesList: actions.setPracticesList,
    setPractice: actions.setPractice,
    clearPracticeLocations: actions.clearPracticeLocations,
    getPracticeLocations: apiActions.getPracticeLocations,
    setPracticeLocations: actions.setPracticeLocations,
};
exports.default = (0, redux_1.compose)((0, react_redux_1.connect)(mapStateToProps, mapDispatchToProps))(PracticeSearchInput);
