"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const components_1 = require("components");
function LTORefundConfirmModal({ isOpen, setIsOpen, beforeSubmit, values, }) {
    const [submitting, setSubmitting] = (0, react_1.useState)(false);
    const handleSubmit = async () => {
        setSubmitting(true);
        await beforeSubmit(values);
        setSubmitting(false);
    };
    return ((0, jsx_runtime_1.jsx)(components_1.Modal, { isOpen: isOpen, setIsOpen: setIsOpen, contentClassName: "with-handle", children: (0, jsx_runtime_1.jsxs)("div", { className: "lto-refund-confirm-modal ", children: [(0, jsx_runtime_1.jsx)(components_1.H1, { variant: "headline-large", children: "Submit Refund" }), (0, jsx_runtime_1.jsx)(components_1.H2, { variant: "title-large", children: "Once submitted, this refund cannot be undone. This action is final. Do you want to proceed?" }), (0, jsx_runtime_1.jsx)(components_1.Button, { type: "button", className: "button-warn", onClick: handleSubmit, submitting: submitting, invalid: submitting, "data-testid": "confirm-lto-refund-button", children: "SUBMIT REFUND" }), (0, jsx_runtime_1.jsx)(components_1.Button, { type: "button", className: "button-warn-outline", onClick: () => setIsOpen(false), invalid: submitting, children: "CANCEL" })] }) }));
}
exports.default = LTORefundConfirmModal;
