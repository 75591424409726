"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_final_form_1 = require("react-final-form");
const components_1 = require("components");
function VoidSaleForm({ handleSubmit, cancelVoidSale }) {
    return ((0, jsx_runtime_1.jsx)(react_final_form_1.Form, { onSubmit: handleSubmit, render: ({ handleSubmit, submitting }) => {
            return ((0, jsx_runtime_1.jsx)("form", { onSubmit: handleSubmit, children: (0, jsx_runtime_1.jsx)("div", { className: "account-summary summary", children: (0, jsx_runtime_1.jsxs)("div", { className: "summary__actions m-t-50", children: [(0, jsx_runtime_1.jsx)("div", { className: "submit", children: (0, jsx_runtime_1.jsx)(components_1.Button, { type: "submit", disabled: submitting, submitting: submitting, className: "button-warn", children: "CONFIRM" }) }), (0, jsx_runtime_1.jsx)("div", { className: "cancel", children: (0, jsx_runtime_1.jsx)("div", { onClick: () => cancelVoidSale(), tabIndex: 0, role: "button", onKeyDown: (e) => {
                                        if (e.key === 'Enter') {
                                            cancelVoidSale();
                                        }
                                    }, children: "CANCEL" }) })] }) }) }));
        } }));
}
exports.default = VoidSaleForm;
