"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createFormStrings = void 0;
const createFormStrings = (status) => {
    if (status === 'disqualify') {
        const formStrings = {
            modalTitle: 'Reject Application',
            modalMessage: 'Are you sure you want to reject this application?',
            errorMessage: 'Form Rejection Error:',
            successMessage: 'Form Rejection Successful',
        };
        return formStrings;
    }
    if (status === 'review') {
        const formStrings = {
            modalTitle: 'Approve Application',
            modalMessage: 'Are you sure you want to approve & submit this application?',
            errorMessage: 'Form Approval Error:',
            successMessage: 'Form Approval Successfully Submitted',
        };
        return formStrings;
    }
    return {
        modalTitle: '',
        modalMessage: '',
        errorMessage: '',
        successMessage: '',
    };
};
exports.createFormStrings = createFormStrings;
