"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const components_1 = require("components");
const config_1 = require("config");
function ContactUs() {
    return ((0, jsx_runtime_1.jsxs)("div", { className: "page-container contact-us-container", children: [(0, jsx_runtime_1.jsx)(components_1.H1, { children: "Alphaeon Credit Practice Support" }), (0, jsx_runtime_1.jsxs)("div", { className: "content", children: [(0, jsx_runtime_1.jsx)(components_1.H2, { variant: "title-extra-large", className: "mar-y-32", children: (0, jsx_runtime_1.jsxs)(components_1.Span, { variant: "title-extra-large", children: ["Phone:", ' ', (0, jsx_runtime_1.jsx)(components_1.A, { href: `tel:${config_1.ALPHAEON_CONTACT_PHONE}`, variant: "title-extra-large", bold: true, children: config_1.ALPHAEON_CONTACT_PHONE })] }) }), (0, jsx_runtime_1.jsx)(components_1.H2, { variant: "title-extra-large", className: "mar-y-32", children: (0, jsx_runtime_1.jsxs)(components_1.Span, { variant: "title-extra-large", children: ["Email:", ' ', (0, jsx_runtime_1.jsx)(components_1.A, { href: `mailto:${config_1.ALPHAEON_CONTACT_EMAIL}`, variant: "title-extra-large", bold: true, children: config_1.ALPHAEON_CONTACT_EMAIL })] }) }), (0, jsx_runtime_1.jsx)(components_1.P, { variant: "body-large", className: "mar-y-32", children: "The practice hotline can assist with enrollments, general questions, and requests, however they do not have access to specific details concerning individual patient accounts." })] })] }));
}
exports.default = (0, react_1.memo)(ContactUs);
