"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useModal = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const internal_portal_components_1 = require("internal-portal-components");
const CANCEL = 'cancel';
const AGREE = 'agree';
function useModal() {
    let promiseResolve = null;
    const [showModal, setShowModal] = (0, react_1.useState)(false);
    const [display, setDisplay] = (0, react_1.useState)({
        title: '',
        message: '',
    });
    const [btnSelected, setBtnSelected] = (0, react_1.useState)(null);
    const resetModal = () => {
        setShowModal(false);
        setDisplay({ title: '', message: '' });
        setBtnSelected(null);
    };
    const handleCancel = (0, react_1.useCallback)(() => {
        setBtnSelected(CANCEL);
        resetModal();
        if (promiseResolve)
            promiseResolve(CANCEL);
    }, [promiseResolve]);
    const handleAgree = (0, react_1.useCallback)(() => {
        setBtnSelected(AGREE);
        resetModal();
        if (promiseResolve)
            promiseResolve(AGREE);
    }, [promiseResolve]);
    const promiseModal = (0, react_1.useCallback)((title, message) => {
        return new Promise((resolve) => {
            setDisplay({ title, message });
            setShowModal(true);
            promiseResolve = resolve; // Hoist the resolve function
        });
    }, [btnSelected]);
    (0, react_1.useEffect)(() => {
        if (display.title || display.message) {
            setShowModal(true);
        }
        else {
            setShowModal(false);
        }
    }, [display]);
    (0, react_1.useEffect)(() => {
        ;
        () => {
            promiseResolve = null;
            resetModal();
        };
    }, []);
    const Modal = () => {
        return ((0, jsx_runtime_1.jsx)(internal_portal_components_1.ModalBasic, { display: display, onAgree: handleAgree, onCancel: handleCancel, onClose: handleCancel }));
    };
    return {
        Modal,
        showModal,
        setShowModal,
        display,
        setDisplay,
        handleCancel,
        handleAgree,
        promiseModal,
        AGREE,
        CANCEL,
    };
}
exports.useModal = useModal;
