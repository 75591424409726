"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_redux_1 = require("react-redux");
const redux_1 = require("redux");
const reducer_1 = require("../reducer");
function ApplicationStepUp({ lenderReferenceId }) {
    return ((0, jsx_runtime_1.jsx)("div", { className: "declined-container", children: (0, jsx_runtime_1.jsxs)("div", { className: "content", children: [(0, jsx_runtime_1.jsx)("h1", { className: "first-title", children: "Application Pending" }), (0, jsx_runtime_1.jsxs)("p", { children: ["Comenity Capital Bank requires additional verification and may be contacting the Patient via text message. If the Patient does not receive the text momentarily, please notify them that we are reviewing the information they provided and they will be notified by mail within 10-14 business days regarding the status of their application.", ' '] }), lenderReferenceId && (0, jsx_runtime_1.jsxs)("p", { children: [" Application ID: ", lenderReferenceId] })] }) }));
}
function mapStateToProps(state) {
    return {
        lenderReferenceId: reducer_1.selectors.lenderReferenceId(state),
    };
}
const mapDispatchToProps = {};
exports.default = (0, redux_1.compose)((0, react_redux_1.connect)(mapStateToProps, mapDispatchToProps))(ApplicationStepUp);
