"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_final_form_1 = require("react-final-form");
const components_1 = require("components");
const utils_1 = require("utils");
function CalculatorForm({ handleSubmit, isFetchingPlans, }) {
    const beforeSubmit = async (values) => {
        await handleSubmit({ amount: (0, utils_1.toAmount)(values.amount) });
    };
    const MAX_AMOUNT_LENGTH = 18;
    return ((0, jsx_runtime_1.jsx)(react_final_form_1.Form, { onSubmit: beforeSubmit, render: ({ handleSubmit, submitting, hasValidationErrors, pristine }) => ((0, jsx_runtime_1.jsxs)("form", { onSubmit: handleSubmit, children: [(0, jsx_runtime_1.jsx)("div", { className: "calculator-form m-t-30", children: (0, jsx_runtime_1.jsx)("div", { className: "calculator-form-container", children: (0, jsx_runtime_1.jsx)(react_final_form_1.Field, { component: components_1.InputNumber, validate: utils_1.validateRequiredField, name: "amount", id: "amount", label: "Total Treatment", preicon: "$", allowLeadingZeros: false, thousandsGroupStyle: "thousand", thousandSeparator: ",", decimalScale: 2, isAllowed: (values) => {
                                const { value } = values;
                                return value.length < MAX_AMOUNT_LENGTH;
                            } }) }) }), (0, jsx_runtime_1.jsx)("div", { className: "calculator-form-submit", children: (0, jsx_runtime_1.jsx)(components_1.Button, { type: "submit", "data-testid": "estimate-payments-button", className: `button-warn`, invalid: submitting || isFetchingPlans || hasValidationErrors || pristine, disabled: submitting || isFetchingPlans || hasValidationErrors || pristine, submitting: submitting, children: "ESTIMATE PAYMENTS" }) })] })) }));
}
exports.default = CalculatorForm;
