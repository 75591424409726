"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_final_form_1 = require("react-final-form");
const components_1 = require("components");
function MissingIDExpirationDateModal({ idType, setIsOpen, isOpen, onSubmit, printReceipt, onSubmitFail, setExpirationDate, }) {
    const [submitting, setSubmitting] = (0, react_1.useState)(false);
    const handleClose = () => {
        setIsOpen(false);
    };
    const formValidate = (values) => {
        if (!values.idExpiration) {
            return { idExpiration: 'ID Expiration Date is required' };
        }
    };
    const formSubmit = (values) => {
        setSubmitting(true);
        if (setExpirationDate) {
            setExpirationDate(values.idExpiration);
        }
        onSubmit(values)
            .then((pdfWindowURL) => {
            printReceipt(pdfWindowURL);
            setIsOpen(false);
            setSubmitting(false);
        })
            .catch((e) => {
            onSubmitFail(e);
            setSubmitting(false);
        });
    };
    return ((0, jsx_runtime_1.jsx)(components_1.Modal, { isOpen: isOpen, setIsOpen: setIsOpen, contentClassName: "alert-modal", modalTestId: "missing-id-expiration-modal", children: (0, jsx_runtime_1.jsxs)("div", { className: "missing-id-expiration-modal ", children: [(0, jsx_runtime_1.jsx)(components_1.H1, { children: "Missing ID Expiration Date" }), (0, jsx_runtime_1.jsx)(components_1.H2, { variant: "title-large", children: "It looks like you haven't provided an ID expiration date. While you can proceed without it, adding this is a required field to document that a valid government issued photo ID has been verified." }), (0, jsx_runtime_1.jsx)(react_final_form_1.Form, { onSubmit: formSubmit, formValidate: formValidate, onSubmitFail: onSubmitFail, children: ({ handleSubmit, pristine, hasValidationErrors }) => {
                        const btnDisabled = submitting;
                        return ((0, jsx_runtime_1.jsxs)("form", { onSubmit: handleSubmit, "data-testid": "missing-id-expiration-form", children: [(0, jsx_runtime_1.jsxs)("div", { className: "input-container-single m-t-30", children: [(0, jsx_runtime_1.jsx)(components_1.Label, { size: "medium", htmlFor: "type", children: "Type of Photo ID" }), (0, jsx_runtime_1.jsx)(react_final_form_1.Field, { id: "type", name: "type", component: 'input', type: "text", disabled: true, "data-testid": "photo-id-type", placeholder: "Type of Photo ID", initialValue: idType })] }), (0, jsx_runtime_1.jsx)("div", { className: "input-container-single", children: (0, jsx_runtime_1.jsx)(components_1.InputDate, { fieldName: "idExpiration", dataTestId: "id-expiration", fieldLabel: "ID Expiration Date" }) }), (0, jsx_runtime_1.jsxs)("div", { className: "m-t-30", children: [(0, jsx_runtime_1.jsx)(components_1.Button, { type: "submit", pristine: pristine || hasValidationErrors || submitting, className: `button-primary button-warn w100 m-t-30 ${btnDisabled ? 'is-disabled' : 'is-enabled'}`, "aria-disabled": btnDisabled && !submitting, children: submitting ? '...Submitting' : 'SAVE & PRINT RECEIPT' }), (0, jsx_runtime_1.jsx)(components_1.Button, { className: "button-warn-outline button-warn button-primary m-t-30 w100", type: "button", onClick: handleClose, children: "ENTER LATER & PRINT RECEIPT" })] })] }));
                    } })] }) }));
}
exports.default = MissingIDExpirationDateModal;
