"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.dateRawSplit = void 0;
const dateRawSplit = ({ dateString, order = [2, 0, 1], separator = '-', }) => {
    const fullDate = dateString.split('/');
    const tmpDate = order.map((itm, idx) => {
        return `${fullDate[itm]}${idx < order.length - 1 ? separator : ''}`;
    });
    return tmpDate.join('');
};
exports.dateRawSplit = dateRawSplit;
