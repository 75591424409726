"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("@coreui/react");
const react_2 = require("react");
const utils_1 = require("utils");
const lendersThatCanBeTurnedOn = ['AFF'];
function LenderDisplay({ lenderName, status, onToggle, locationId, isDisabled, }) {
    const [switchChecked, setSwitchChecked] = (0, react_2.useState)(false);
    const [modalVisible, setModalVisible] = (0, react_2.useState)(false);
    const [errorMessage, setErrorMessage] = (0, react_2.useState)(null);
    const [loading, setLoading] = (0, react_2.useState)(false);
    const [lenderStatus, setLenderStatus] = (0, react_2.useState)(initiateLenderStatus());
    function initiateLenderStatus() {
        if (!lendersThatCanBeTurnedOn.includes(lenderName) &&
            status === 'configured') {
            return 'turn_on_not_supported';
        }
        return status;
    }
    (0, react_2.useEffect)(() => {
        setSwitchChecked(lenderStatus === 'on');
    }, []);
    const handleSwitchChange = (event) => {
        if (event.target.checked) {
            setModalVisible(true);
        }
        else {
            setSwitchChecked(false);
        }
    };
    const handleModalAccept = () => {
        setLoading(true);
        onToggle(lenderName, true).then((res) => {
            setLoading(false);
            if (res.error) {
                setErrorMessage(res.error.data.error);
            }
            else {
                setLenderStatus('on');
                setSwitchChecked(!switchChecked);
                setModalVisible(false);
            }
        });
    };
    const handleModalCancel = () => {
        setErrorMessage(null);
        setModalVisible(false);
    };
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsxs)("div", { className: "lender-display", children: [(0, jsx_runtime_1.jsx)("span", { className: "lender-name", children: lenderName }), (0, jsx_runtime_1.jsx)(react_1.CFormSwitch, { label: lenderStatus == 'not_configured' ||
                            lenderStatus == 'turn_on_not_supported'
                            ? (0, utils_1.titleCase)(lenderStatus)
                            : '', className: "toggle-button", disabled: lenderStatus != 'configured' || isDisabled, checked: switchChecked, onChange: handleSwitchChange })] }), (0, jsx_runtime_1.jsxs)(react_1.CModal, { visible: modalVisible, onClose: handleModalCancel, portal: false, children: [(0, jsx_runtime_1.jsx)(react_1.CModalHeader, { children: "Confirm Turn on Lender " }), (0, jsx_runtime_1.jsxs)(react_1.CModalBody, { children: [errorMessage && (0, jsx_runtime_1.jsx)(react_1.CAlert, { color: "danger", children: errorMessage }), "Are you sure you want to turn on ", lenderName, " for location Id", ' ', locationId, "?"] }), (0, jsx_runtime_1.jsxs)(react_1.CModalFooter, { children: [(0, jsx_runtime_1.jsx)(react_1.CButton, { color: "secondary", onClick: handleModalCancel, children: "Cancel" }), (0, jsx_runtime_1.jsxs)(react_1.CButton, { color: "primary", onClick: handleModalAccept, disabled: loading, children: ["Accept ", loading && (0, jsx_runtime_1.jsx)(react_1.CSpinner, {})] })] })] })] }));
}
exports.default = LenderDisplay;
