"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.usePatchMerchantManagementV2TransactionsTransactionIdReceiptsReceiptIdSignMutation = exports.usePostMerchantManagementV2TransactionsTransactionIdReceiptsReceiptIdSendMutation = exports.usePostMerchantManagementV2TransactionsSaleMutation = exports.usePostMerchantManagementV2TransactionsRefundMutation = exports.usePatchMerchantManagementV2TransactionsVoidMutation = exports.useGetMerchantManagementV2TransactionsTransactionIdReceiptsOptionsQuery = exports.useGetMerchantManagementV2TransactionsTransactionIdReceiptsReceiptIdQuery = exports.useGetMerchantManagementV2TransactionsTransactionIdReceiptsQuery = exports.usePostMerchantManagementV2TransactionsTransactionIdReceiptsMutation = exports.usePostMerchantManagementV2TransactionsReceiptMutation = exports.merchantManagementV2Api = void 0;
const merchantApiSlice_1 = require("../merchantApiSlice");
const injectedRtkApi = merchantApiSlice_1.api.injectEndpoints({
    endpoints: (build) => ({
        postMerchantManagementV2TransactionsReceipt: build.mutation({
            query: (queryArg) => ({
                url: `/merchant_management/v2/transactions/receipt`,
                method: 'POST',
                body: queryArg.body,
            }),
        }),
        postMerchantManagementV2TransactionsTransactionIdReceipts: build.mutation({
            query: (queryArg) => ({
                url: `/merchant_management/v2/transactions/${queryArg.transactionId}/receipts`,
                method: 'POST',
                body: queryArg.body,
            }),
        }),
        getMerchantManagementV2TransactionsTransactionIdReceipts: build.query({
            query: (queryArg) => ({
                url: `/merchant_management/v2/transactions/${queryArg.transactionId}/receipts`,
            }),
        }),
        getMerchantManagementV2TransactionsTransactionIdReceiptsReceiptId: build.query({
            query: (queryArg) => ({
                url: `/merchant_management/v2/transactions/${queryArg.transactionId}/receipts/${queryArg.receiptId}`,
            }),
        }),
        getMerchantManagementV2TransactionsTransactionIdReceiptsOptions: build.query({
            query: (queryArg) => ({
                url: `/merchant_management/v2/transactions/${queryArg.transactionId}/receipts/options`,
            }),
        }),
        patchMerchantManagementV2TransactionsVoid: build.mutation({
            query: (queryArg) => ({
                url: `/merchant_management/v2/transactions/void`,
                method: 'PATCH',
                body: queryArg.body,
            }),
        }),
        postMerchantManagementV2TransactionsRefund: build.mutation({
            query: (queryArg) => ({
                url: `/merchant_management/v2/transactions/refund`,
                method: 'POST',
                body: queryArg.postMerchantManagementV2TransactionsRefund,
            }),
        }),
        postMerchantManagementV2TransactionsSale: build.mutation({
            query: (queryArg) => ({
                url: `/merchant_management/v2/transactions/sale`,
                method: 'POST',
                body: queryArg.postMerchantManagementV2TransactionsSale,
            }),
        }),
        postMerchantManagementV2TransactionsTransactionIdReceiptsReceiptIdSend: build.mutation({
            query: (queryArg) => ({
                url: `/merchant_management/v2/transactions/${queryArg.transactionId}/receipts/${queryArg.receiptId}/send`,
                method: 'POST',
                body: queryArg.body,
            }),
        }),
        patchMerchantManagementV2TransactionsTransactionIdReceiptsReceiptIdSign: build.mutation({
            query: (queryArg) => ({
                url: `/merchant_management/v2/transactions/${queryArg.transactionId}/receipts/${queryArg.receiptId}/sign`,
                method: 'PATCH',
                body: queryArg.body,
            }),
        }),
    }),
    overrideExisting: false,
});
exports.merchantManagementV2Api = injectedRtkApi;
exports.usePostMerchantManagementV2TransactionsReceiptMutation = injectedRtkApi.usePostMerchantManagementV2TransactionsReceiptMutation, exports.usePostMerchantManagementV2TransactionsTransactionIdReceiptsMutation = injectedRtkApi.usePostMerchantManagementV2TransactionsTransactionIdReceiptsMutation, exports.useGetMerchantManagementV2TransactionsTransactionIdReceiptsQuery = injectedRtkApi.useGetMerchantManagementV2TransactionsTransactionIdReceiptsQuery, exports.useGetMerchantManagementV2TransactionsTransactionIdReceiptsReceiptIdQuery = injectedRtkApi.useGetMerchantManagementV2TransactionsTransactionIdReceiptsReceiptIdQuery, exports.useGetMerchantManagementV2TransactionsTransactionIdReceiptsOptionsQuery = injectedRtkApi.useGetMerchantManagementV2TransactionsTransactionIdReceiptsOptionsQuery, exports.usePatchMerchantManagementV2TransactionsVoidMutation = injectedRtkApi.usePatchMerchantManagementV2TransactionsVoidMutation, exports.usePostMerchantManagementV2TransactionsRefundMutation = injectedRtkApi.usePostMerchantManagementV2TransactionsRefundMutation, exports.usePostMerchantManagementV2TransactionsSaleMutation = injectedRtkApi.usePostMerchantManagementV2TransactionsSaleMutation, exports.usePostMerchantManagementV2TransactionsTransactionIdReceiptsReceiptIdSendMutation = injectedRtkApi.usePostMerchantManagementV2TransactionsTransactionIdReceiptsReceiptIdSendMutation, exports.usePatchMerchantManagementV2TransactionsTransactionIdReceiptsReceiptIdSignMutation = injectedRtkApi.usePatchMerchantManagementV2TransactionsTransactionIdReceiptsReceiptIdSignMutation;
