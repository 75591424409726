"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const components_1 = require("components");
function RevolvingPlanWarnModal({ isOpen, setIsOpen, setModalShown, }) {
    return ((0, jsx_runtime_1.jsx)(components_1.Modal, { isOpen: isOpen, setIsOpen: setIsOpen, contentClassName: "with-handle", closeBtnTestId: "close-revolving-plan-warn-modal", children: (0, jsx_runtime_1.jsxs)("div", { className: "revolving-plan-warn-modal", children: [(0, jsx_runtime_1.jsx)("h4", { children: "Revolving Plan" }), (0, jsx_runtime_1.jsx)("p", { children: "By selecting the Revolving Plan option, the patient will be charged the standard purchase APR of 32.99% and the full amount charged will be due when the patient receives their first statement." }), (0, jsx_runtime_1.jsx)("p", { children: "If you\u2019d like to put this transaction on a promotional plan, please go back and select one. If no promotional plans are listed, it means the purchase amount does not meet the program minimum." }), (0, jsx_runtime_1.jsxs)("div", { className: "d-flex-center__column w100", children: [(0, jsx_runtime_1.jsx)(components_1.Button, { type: "button", className: "button-warn", onClick: () => {
                                setIsOpen(false);
                                setModalShown(true);
                            }, children: "CONTINUE WITH REVOLVING PLAN" }), (0, jsx_runtime_1.jsx)(components_1.Button, { type: "button", className: "button-primary button-warn-outline", onClick: () => {
                                setIsOpen(false);
                            }, children: "CLOSE" })] })] }) }));
}
exports.default = RevolvingPlanWarnModal;
