"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("@coreui/react");
const react_final_form_1 = require("react-final-form");
const components_1 = require("components");
const FieldsBusinessAddress = () => {
    return ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: (0, jsx_runtime_1.jsx)("div", { className: "", children: (0, jsx_runtime_1.jsxs)(react_1.CRow, { xs: { gutterX: 5, gutterY: 3 }, children: [(0, jsx_runtime_1.jsx)(react_1.CCol, { lg: 12, md: 12, children: (0, jsx_runtime_1.jsx)(react_final_form_1.Field, { altclass: "coreUI", name: "businessAddress", label: 'Address', component: components_1.InputText, placeholder: "", type: "text" }) }), (0, jsx_runtime_1.jsx)(react_1.CCol, { lg: 6, md: 12, children: (0, jsx_runtime_1.jsx)(react_final_form_1.Field, { altclass: "coreUI", name: "businessCity", label: 'City', component: components_1.InputText, placeholder: "", type: "text" }) }), (0, jsx_runtime_1.jsx)(react_1.CCol, { lg: 3, md: 12, children: (0, jsx_runtime_1.jsx)(components_1.InputSelectNative, { name: "businessState", label: 'State', altclass: "coreUI" }) }), (0, jsx_runtime_1.jsx)(react_1.CCol, { lg: 3, md: 12, children: (0, jsx_runtime_1.jsx)(react_final_form_1.Field, { name: "businessZip", altclass: "coreUI", label: `Zip Code`, component: components_1.InputNumber, placeholder: "Enter zip", type: "text", isAllowed: (values) => {
                                const { floatValue, value, formattedValue } = values;
                                return (value.length <= 5 ||
                                    (value === '' &&
                                        formattedValue === '' &&
                                        floatValue === undefined));
                            } }) })] }) }) }));
};
exports.default = FieldsBusinessAddress;
