"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jwt_decode_1 = require("jwt-decode");
const utils_1 = require("utils");
function getUserEmail() {
    const cookie = JSON.parse((0, utils_1.getCookie)('portalUser'));
    const creds = (0, jwt_decode_1.jwtDecode)(cookie.credential);
    return creds.email;
}
exports.default = getUserEmail;
