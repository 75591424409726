"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const lodash_1 = require("lodash");
function InputCheckbox({ input, meta, dataTestId, hideBreakTag = false, rest, }) {
    const cleanRest = (0, lodash_1.omit)(rest, ['label', 'dataTestId', 'hideBreakTag']);
    return ((0, jsx_runtime_1.jsxs)("span", { children: [(meta.error || meta.submitError) && meta.touched && ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)("span", { className: "error-message", children: meta.error || meta.submitError }), (0, jsx_runtime_1.jsx)("br", {})] })), hideBreakTag ? null : (0, jsx_runtime_1.jsx)("br", {}), (0, jsx_runtime_1.jsx)("input", { ...input, ...cleanRest, type: "checkbox", "data-testid": dataTestId })] }));
}
exports.default = InputCheckbox;
