"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("@coreui/react");
const react_2 = require("react");
const react_toastify_1 = require("react-toastify");
const components_1 = require("components");
function ToastShell() {
    // Vars
    const htmlEl = document.querySelector('html');
    const config = { attributes: true, childList: false, subtree: false };
    // Hook
    const coreuiColor = (0, react_1.useColorModes)('coreui-free-react-admin-template-theme');
    // State
    const [theme, setTheme] = (0, react_2.useState)(coreuiColor.colorMode);
    // Func
    const callback = (mutationList) => {
        for (const mutation of mutationList) {
            if (mutation.type === 'attributes') {
                const attrVal = mutation.target?.attributes.getNamedItem('data-coreui-theme')?.nodeValue;
                setTheme(attrVal);
            }
        }
    };
    // Effect
    (0, react_2.useEffect)(() => {
        const observer = new MutationObserver(callback);
        observer.observe(htmlEl, config);
        () => {
            observer.disconnect();
        };
    }, []);
    return ((0, jsx_runtime_1.jsx)(react_toastify_1.ToastContainer, { closeButton: components_1.ToastDismissButton, theme: theme ?? 'dark' }));
}
exports.default = ToastShell;
