"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const lodash_1 = require("lodash");
const lp_components_1 = require("lp-components");
const react_1 = require("react");
const react_redux_1 = require("react-redux");
const react_router_dom_1 = require("react-router-dom");
const redux_1 = require("redux");
const redux_form_1 = require("redux-form");
const apiActions = __importStar(require("api-actions"));
const components_1 = require("components");
const config_1 = require("config");
const merchant_portal_components_1 = require("merchant-portal-components");
const utils_1 = require("utils");
const actions_1 = require("../actions");
const forms_1 = require("../forms");
const reducer_1 = require("../reducer");
function UserList({ userStatus, getManagers, managers, searchQuery, }) {
    const [managerPaginatedData, setManagerPaginatedData] = (0, react_1.useState)([]);
    (0, react_1.useEffect)(() => {
        getManagers();
        return () => {
            (0, actions_1.clearManagers)();
        };
    }, []);
    const managerData = (0, react_1.useMemo)(() => {
        let filteredData = userStatus
            ? managers?.filter((user) => userStatus?.includes(user.status))
            : managers;
        if (searchQuery) {
            filteredData = filteredData.filter((fd) => {
                const name = `${fd.firstName} ${fd.lastName}`.toLowerCase();
                const email = fd.email;
                return (name?.includes(searchQuery.toLowerCase()) ||
                    email?.includes(searchQuery.toLowerCase()));
            });
        }
        return filteredData;
    }, [userStatus, searchQuery, managers]);
    const handlePageChange = (paginatedData) => {
        setManagerPaginatedData(paginatedData);
    };
    if ((0, lodash_1.isEmpty)(managers) || (0, lodash_1.isUndefined)(managers)) {
        return (0, jsx_runtime_1.jsx)(components_1.Spinner, { centerPage: true });
    }
    return ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: (0, jsx_runtime_1.jsxs)("div", { className: "merchant dashboard users", children: [(0, jsx_runtime_1.jsx)(components_1.H1, { variant: "headline-large", children: "Manage Users" }), (0, jsx_runtime_1.jsxs)("div", { className: "users__add-user m-t-30", children: [' ', (0, jsx_runtime_1.jsx)(merchant_portal_components_1.AuthorizedLinkButton, { to: config_1.USER_ADD_URL, className: "button-primary button-warn-outline", requiredPermission: config_1.PERMISSION.MANAGE_USERS, children: "Add User" })] }), (0, jsx_runtime_1.jsxs)("div", { className: "dashboard__activities--table m-t-30 users__table", children: [(0, jsx_runtime_1.jsx)(forms_1.UserFilterForm, { onSubmit: (e) => {
                                e.preventDefault();
                            }, initialValues: {
                                searchQuery: '',
                                userStatus: ['active'],
                            } }), managerData.length > 0 ? ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsxs)(lp_components_1.Table, { data: managerPaginatedData, children: [(0, jsx_runtime_1.jsx)(lp_components_1.TableColumn, { label: "USER", name: "firstName", component: ({ data }) => ((0, jsx_runtime_1.jsx)("td", { style: {
                                                    cursor: 'pointer',
                                                }, children: (0, jsx_runtime_1.jsxs)(react_router_dom_1.Link, { to: {
                                                        pathname: `${config_1.USER_UPDATE_URL}${data.managerId}`,
                                                    }, children: [data.firstName, " ", data.lastName] }) })) }), (0, jsx_runtime_1.jsx)(lp_components_1.TableColumn, { name: "email", label: "EMAIL", component: ({ data }) => ((0, jsx_runtime_1.jsx)("td", { className: "login", children: data.email })) }), (0, jsx_runtime_1.jsx)(lp_components_1.TableColumn, { name: "status", label: "STATUS", component: StatusHighlightCell }), (0, jsx_runtime_1.jsx)(lp_components_1.TableColumn, { name: "createdAt", label: "DATE CREATED", format: utils_1.formatISODateStringAsUSMonthDayYear }), (0, jsx_runtime_1.jsx)(lp_components_1.TableColumn, { name: "updatedAt", label: "LAST UPDATED", format: utils_1.formatISODateStringAsUSMonthDayYear })] }), (0, jsx_runtime_1.jsx)("div", { className: "m-b-20", children: (0, jsx_runtime_1.jsx)(components_1.Pagination, { handlePageChange: handlePageChange, data: managerData }) })] })) : ((0, jsx_runtime_1.jsx)("div", { className: "empty-state-container", children: (0, jsx_runtime_1.jsx)(components_1.P, { variant: "body-large", children: "No Users" }) }))] })] }) }));
}
const StatusHighlightCell = ({ value }) => {
    return ((0, jsx_runtime_1.jsx)("td", { style: {
            color: value === 'inactive'
                ? '#bf2536' /* $red-base */
                : value === 'active'
                    ? '#43a03c' /* $green-base */
                    : '',
            textTransform: 'uppercase',
            fontWeight: 800,
        }, children: value }));
};
const selector = (0, redux_form_1.formValueSelector)('userFilterForm');
function mapStateToProps(state) {
    return {
        managers: reducer_1.selectors.managers(state),
        userStatus: selector(state, 'userStatus'),
        searchQuery: selector(state, 'searchQuery'),
    };
}
const mapDispatchToProps = {};
const mapApiAuthToProps = {
    getManagers: apiActions.getManagers,
};
exports.default = (0, redux_1.compose)((0, react_redux_1.connect)(mapStateToProps, mapDispatchToProps), (0, utils_1.withApiAuth)(mapApiAuthToProps))(UserList);
