"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const date_fns_1 = require("date-fns");
const lp_components_1 = require("lp-components");
const components_1 = require("components");
const utils_1 = require("utils");
const forms_1 = require("../forms");
function LoanAdjustConfirmationModal({ adjustmentData, handleClose, accountDetail, handleConfirm, handleSubmitSuccess, handleSubmitFailure, }) {
    const { serviceDate, creditLimit } = accountDetail;
    return ((0, jsx_runtime_1.jsx)(lp_components_1.Modal, { onClose: () => {
            handleClose();
        }, hideCloseButton: true, className: "modal-inner prequal-decline-modal modal", children: (0, jsx_runtime_1.jsxs)("div", { className: "sales-confirmation", children: [(0, jsx_runtime_1.jsxs)("div", { className: "sales-confirmation__container", children: [(0, jsx_runtime_1.jsx)(components_1.H3, { className: "title", variant: "headline-small", children: "Confirm Loan Adjust Details" }), (0, jsx_runtime_1.jsxs)("table", { className: "details m-t-20", children: [adjustmentData?.serviceDate && ((0, jsx_runtime_1.jsx)("tr", { children: (0, jsx_runtime_1.jsx)("td", { className: "", children: (0, jsx_runtime_1.jsxs)(components_1.P, { variant: "body-large", children: ["Please confirm the service date adjustment from", ' ', (0, date_fns_1.format)(new Date(serviceDate), 'MM/dd/yyyy'), " to", ' ', (0, date_fns_1.format)(new Date(adjustmentData?.serviceDate), 'MM/dd/yyyy')] }) }) })), adjustmentData?.amount && ((0, jsx_runtime_1.jsx)("tr", { children: (0, jsx_runtime_1.jsx)("td", { className: "", children: (0, jsx_runtime_1.jsxs)(components_1.P, { variant: "body-large", children: ["Please confirm the adjusted amount from", ' ', (0, utils_1.formatCurrency)(creditLimit, 2), " to", ' ', (0, utils_1.formatCurrency)(adjustmentData?.amount, 2), " is accurate as it cannot be increased after the update."] }) }) }))] })] }), (0, jsx_runtime_1.jsx)(forms_1.LoanAdjustmentConfirmationForm, { onSubmit: handleConfirm, onSubmitSuccess: handleSubmitSuccess, onSubmitFail: handleSubmitFailure, handleClose: handleClose })] }) }));
}
exports.default = LoanAdjustConfirmationModal;
