"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("@coreui/react");
const react_2 = require("react");
const react_final_form_1 = require("react-final-form");
const components_1 = require("components");
const internal_portal_components_1 = require("internal-portal-components");
const FieldsMailingAddress = () => {
    const form = (0, react_final_form_1.useForm)();
    const formState = (0, react_final_form_1.useFormState)();
    const sameAddress = formState.values['sameAddress'];
    const businessAddress = formState.values['businessAddress'];
    const businessCity = formState.values['businessCity'];
    const businessState = formState.values['businessState'];
    const businessZip = formState.values['businessZip'];
    const mailingAddress = formState.values['mailingAddress'];
    const mailingCity = formState.values['mailingCity'];
    const mailingState = formState.values['mailingState'];
    const mailingZip = formState.values['mailingZip'];
    const [initAddress, setInitAddress] = (0, react_2.useState)(mailingAddress);
    const [initCity, setInitCity] = (0, react_2.useState)(mailingCity);
    const [initState, setInitState] = (0, react_2.useState)(mailingState);
    const [initZip, setInitZip] = (0, react_2.useState)(mailingZip);
    const [isSameAddress, setIsSameAddress] = (0, react_2.useState)(sameAddress);
    // When props sameAddress is updated, set the state locally
    (0, react_2.useEffect)(() => {
        setIsSameAddress(sameAddress);
    }, [sameAddress]);
    // When same address checkbox is toggled, swap the data between mailinlg and initial data
    (0, react_2.useEffect)(() => {
        if (isSameAddress) {
            form.change('mailingAddress', businessAddress);
            form.change('mailingCity', businessCity);
            form.change('mailingState', businessState);
            form.change('mailingZip', businessZip);
        }
        else {
            form.change('mailingAddress', initAddress);
            form.change('mailingCity', initCity);
            form.change('mailingState', initState);
            form.change('mailingZip', initZip);
        }
    }, [isSameAddress]);
    // If same address, keep business and mailing addresses the same
    (0, react_2.useEffect)(() => {
        if (isSameAddress) {
            form.change('mailingAddress', businessAddress);
            form.change('mailingCity', businessCity);
            form.change('mailingState', businessState);
            form.change('mailingZip', businessZip);
        }
    }, [businessAddress, businessCity, businessState, businessZip]);
    // If you started editing mailing, record those changes
    (0, react_2.useEffect)(() => {
        if (!isSameAddress) {
            setInitAddress(mailingAddress);
            setInitCity(mailingCity);
            setInitState(mailingState);
            setInitZip(mailingZip);
        }
        else {
            setInitAddress(businessAddress);
            setInitCity(businessCity);
            setInitState(businessState);
            setInitZip(businessZip);
        }
    }, [mailingAddress, mailingCity, mailingState, mailingZip, sameAddress]);
    return ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: (0, jsx_runtime_1.jsxs)("div", { className: "", children: [(0, jsx_runtime_1.jsx)(internal_portal_components_1.FormSubTitle, { title: "Mailing Address", checkboxName: "sameAddress", checkboxText: "Same as business address" }), (0, jsx_runtime_1.jsxs)(react_1.CRow, { xs: { gutterX: 5, gutterY: 3 }, children: [(0, jsx_runtime_1.jsx)(react_1.CCol, { lg: 12, md: 12, children: (0, jsx_runtime_1.jsx)(react_final_form_1.Field, { altclass: "coreUI", name: "mailingAddress", label: 'Address', component: components_1.InputText, placeholder: "", type: "text", disabled: sameAddress }) }), (0, jsx_runtime_1.jsx)(react_1.CCol, { lg: 6, md: 12, children: (0, jsx_runtime_1.jsx)(react_final_form_1.Field, { altclass: "coreUI", name: "mailingCity", label: 'City', component: components_1.InputText, placeholder: "", type: "text", disabled: sameAddress }) }), (0, jsx_runtime_1.jsx)(react_1.CCol, { lg: 3, md: 12, children: (0, jsx_runtime_1.jsx)(components_1.InputSelectNative, { name: "mailingState", label: 'State', altclass: "coreUI", disabled: sameAddress }) }), (0, jsx_runtime_1.jsx)(react_1.CCol, { lg: 3, md: 12, children: (0, jsx_runtime_1.jsx)(react_final_form_1.Field, { disabled: sameAddress, name: "mailingZip", altclass: "coreUI", label: `Zip Code`, component: components_1.InputNumber, placeholder: "Enter zip", type: "text", isAllowed: (values) => {
                                    const { floatValue, value, formattedValue } = values;
                                    return (value.length <= 5 ||
                                        (value === '' &&
                                            formattedValue === '' &&
                                            floatValue === undefined));
                                } }) })] })] }) }));
};
exports.default = FieldsMailingAddress;
