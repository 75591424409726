"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const date_fns_1 = require("date-fns");
const date_fns_tz_1 = require("date-fns-tz");
const lodash_1 = require("lodash");
const react_1 = require("react");
const react_redux_1 = require("react-redux");
const redux_1 = require("redux");
const redux_flash_1 = require("redux-flash");
const redux_form_1 = require("redux-form");
const apiActions = __importStar(require("api-actions"));
const components_1 = require("components");
const config_1 = require("config");
const merchant_management_v1_api_1 = require("merchant-management-v1-api");
const merchant_portal_components_1 = require("merchant-portal-components");
const utils_1 = require("utils");
const forms_1 = require("../../forms");
const reducer_1 = require("../../reducer");
function DashboardTables({ currentPermissions, currentLocation, flashErrorMessage, applicationStatus, fetchAllLocationsForManager, }) {
    // Vars
    const isActivityTableAccessible = (0, utils_1.operationPermitted)(currentPermissions, config_1.PERMISSION.APPLICATION_ACTIVITY_TABLE);
    const [getSalesReports, { data: salesReports, isError: salesReportError }] = (0, merchant_management_v1_api_1.usePostMerchantManagementV1ReportsSalesMutation)({
        fixedCacheKey: 'salesReports',
    });
    const [getApplicationsReport, { data: applicationReports, isError: applicationReportError },] = (0, merchant_management_v1_api_1.usePostMerchantManagementV1ReportsApplicationsMutation)({
        fixedCacheKey: 'applicationsReport',
    });
    // State
    const [displayedNextDayTransactions, setDisplayedNextDayTransactions] = (0, react_1.useState)([]);
    const [displayedPendingTransactions, setDisplayedPendingTransactions] = (0, react_1.useState)([]);
    // Dates
    const todaysDate = new Date();
    const startDate = (0, date_fns_1.subDays)(todaysDate, config_1.REPORTS_START_OFFSET_DAYS);
    const endDate = new Date();
    endDate.setDate(todaysDate.getDate() + 1);
    const shouldShowNextDayPendingAccessLoanTransaction = (transaction) => {
        return (transaction.status &&
            (0, utils_1.checkIfESTTimeisPast10Pm)(transaction.installmentServiceDate) &&
            config_1.PENDING_STATES.includes(transaction.status));
    };
    const accessLoanTransactionsForNextDay = (0, react_1.useMemo)(() => {
        return salesReports?.transactions
            .filter((transaction) => {
            const productType = (0, utils_1.mapTransactionTypeandInstrumentTypeToProductType)(transaction.transactionType, transaction.instrumentType);
            if (productType === config_1.PRODUCT_TYPE_MAP.installment) {
                return transaction;
            }
        })
            .sort((a, b) => new Date(b.installmentServiceDate).getTime() -
            new Date(a.installmentServiceDate).getTime());
    }, [salesReports]);
    const lineOfCreditTransactionsForNextDay = (0, react_1.useMemo)(() => {
        return salesReports?.transactions
            .filter((transaction) => {
            const productType = (0, utils_1.mapTransactionTypeandInstrumentTypeToProductType)(transaction.transactionType, transaction.instrumentType);
            if (productType === config_1.PRODUCT_TYPE_MAP.line_of_credit) {
                return transaction;
            }
        })
            .sort((a, b) => new Date(b.effectiveAt ?? 0).getTime() -
            new Date(a.effectiveAt ?? 0).getTime());
    }, [salesReports]);
    const nextDayTransactions = (0, react_1.useMemo)(() => {
        const mergedTransaction = new Array().concat(lineOfCreditTransactionsForNextDay?.filter((transaction) => {
            return (transaction?.effectiveAt &&
                transaction?.status &&
                (0, utils_1.checkIfESTTimeisPast10Pm)(transaction?.effectiveAt) &&
                config_1.LOC_NEXT_DAY_PENDING_STATES.includes(transaction?.status));
        }), accessLoanTransactionsForNextDay?.filter((transaction) => {
            return (transaction != undefined &&
                shouldShowNextDayPendingAccessLoanTransaction(transaction));
        }));
        return mergedTransaction;
    }, [accessLoanTransactionsForNextDay, lineOfCreditTransactionsForNextDay]);
    const scheduledPendingTransaction = (0, react_1.useMemo)(() => {
        const lineOfCreditTransactionsForScheduledPending = salesReports?.transactions.filter((transaction) => {
            return (config_1.PRODUCT_TYPE_MAP.line_of_credit ===
                (0, utils_1.mapTransactionTypeandInstrumentTypeToProductType)(transaction.transactionType, transaction.instrumentType) && config_1.TRANSACTION_STATUS.PENDING_SIGNATURE === transaction?.status);
        });
        const accessLoanTransactionsForScheduledPending = salesReports?.transactions.filter((transaction) => {
            (0, utils_1.mapTransactionTypeandInstrumentTypeToProductType)(transaction.transactionType, transaction.instrumentType);
            return (config_1.PRODUCT_TYPE_MAP.installment ===
                (0, utils_1.mapTransactionTypeandInstrumentTypeToProductType)(transaction.transactionType, transaction.instrumentType) &&
                (config_1.TRANSACTION_STATUS.PENDING_SIGNATURE === transaction?.status ||
                    config_1.TRANSACTION_STATUS.SIGNED === transaction?.status) &&
                !shouldShowNextDayPendingAccessLoanTransaction(transaction));
        });
        const mergedTransaction = new Array()
            .concat(lineOfCreditTransactionsForScheduledPending, accessLoanTransactionsForScheduledPending)
            .sort((a, b) => new Date(b.effectiveAt).getTime() - new Date(a.effectiveAt).getTime());
        return mergedTransaction;
    }, [salesReports]);
    const applications = (0, react_1.useMemo)(() => {
        if ((0, lodash_1.isEmpty)(applicationStatus))
            return [];
        const filteredData = applicationStatus.includes('All')
            ? applicationReports?.applications
            : applicationReports?.applications?.filter((application) => applicationStatus.includes(application.applicationStatus));
        const dataWithDisplayAmount = filteredData?.map((application) => {
            return {
                ...application,
                displayAmount: application.creditLimit,
                // ? application.creditLimit
                // : application.requestedAmount,
                // re: MC-1191: uncomment the above two lines when API is fixed
            };
        });
        return dataWithDisplayAmount?.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
    }, [applicationStatus, applicationReports]);
    const fetchApplications = (values = {
        startDate: undefined,
        endDate: undefined,
    }) => {
        const reportCriteria = {
            startDate: (0, date_fns_1.format)(values.startDate || startDate, 'yyyy-MM-dd'),
            endDate: (0, date_fns_1.format)(values.endDate || endDate, 'yyyy-MM-dd'),
        };
        const data = {
            postMerchantManagementV1ReportsApplications: {
                ...reportCriteria,
                locationIds: [currentLocation?.locationId],
            },
        };
        getApplicationsReport(data);
    };
    const fetchSalesReport = (values = {
        startDate: undefined,
        endDate: undefined,
    }) => {
        const reportCriteria = {
            startDate: (0, date_fns_tz_1.formatInTimeZone)(values.startDate || startDate, config_1.PST_TIMEZONE, 'yyyy-MM-dd'),
            endDate: (0, date_fns_tz_1.formatInTimeZone)(values.endDate || endDate, config_1.PST_TIMEZONE, 'yyyy-MM-dd'),
        };
        const data = {
            postMerchantManagementV1ReportsSales: {
                ...reportCriteria,
                locationIds: [currentLocation?.locationId],
            },
        };
        getSalesReports(data);
    };
    (0, react_1.useEffect)(() => {
        fetchSalesReport();
        fetchApplications();
        fetchAllLocationsForManager();
    }, [currentLocation.locationId]);
    const handleActivityFilter = (values) => {
        const tmpStartDate = values.startDate || startDate;
        const tmpEndDate = values.endDate || endDate;
        const isDateRangeInvalid = (0, date_fns_1.isAfter)(tmpStartDate, tmpEndDate);
        // Check Date Range
        if (isDateRangeInvalid) {
            flashErrorMessage('INVALID DATE RANGE! Start Date is after End Date.', {
                timeout: 3000,
            });
            return;
        }
        const reportCriteria = {
            startDate: (0, date_fns_1.format)(tmpStartDate, 'yyyy-MM-dd'),
            endDate: (0, date_fns_1.format)(tmpEndDate, 'yyyy-MM-dd'),
        };
        getApplicationsReport({
            postMerchantManagementV1ReportsApplications: {
                ...reportCriteria,
                locationIds: [currentLocation?.locationId],
            },
        });
    };
    const handleNextDayTransactionPageChange = (paginatedData) => {
        setDisplayedNextDayTransactions(paginatedData);
    };
    const handlePendingTransactionPageChange = (paginatedData) => {
        setDisplayedPendingTransactions(paginatedData);
    };
    const handleFilters = (filterCriteria, data) => {
        const { filterOption, searchField } = filterCriteria;
        if (!searchField) {
            return data;
        }
        const filteredData = data.filter((transaction) => {
            if (filterOption === config_1.TRANSACTION_FILTERS.CUSTOMER_NAME) {
                const fullName = (0, utils_1.getFullName)(transaction.name).toLowerCase();
                return fullName.includes(searchField.toLowerCase());
            }
            else if (filterOption === config_1.TRANSACTION_FILTERS.ACCOUNT_NUMBER) {
                return transaction.alphaeonAccountNumber.includes(searchField);
            }
        });
        return filteredData;
    };
    const handleNextTransactionFilter = (values) => {
        const data = handleFilters(values, nextDayTransactions);
        setDisplayedNextDayTransactions(data);
    };
    const handlePendingTransactionFilter = (values) => {
        const data = handleFilters(values, scheduledPendingTransaction);
        setDisplayedPendingTransactions(data);
    };
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsxs)("div", { className: "dashboard__transactions m-t-50", children: [(0, jsx_runtime_1.jsx)("div", { className: "dashboard__transactions--title", children: "Next Day Pending Transactions" }), (0, jsx_runtime_1.jsx)("div", { className: "dashboard__activities--table m-t-30 m-b-30", children: (0, jsx_runtime_1.jsx)(merchant_portal_components_1.TransactionTable, { formKey: 'next-day-transactions', displayedTransactions: displayedNextDayTransactions, salesReports: salesReports, fetchError: salesReportError, initialValues: {
                                filterOption: config_1.TRANSACTION_FILTERS.CUSTOMER_NAME,
                            }, allTransactions: nextDayTransactions, handlePageChange: handleNextDayTransactionPageChange, handleFilters: handleNextTransactionFilter }) })] }), (0, jsx_runtime_1.jsxs)("div", { className: "dashboard__transactions m-t-50", children: [(0, jsx_runtime_1.jsx)("div", { className: "dashboard__transactions--title", children: "Scheduled Pending Transactions" }), (0, jsx_runtime_1.jsx)("div", { className: "dashboard__activities--table m-t-30 m-b-30", children: (0, jsx_runtime_1.jsx)(merchant_portal_components_1.TransactionTable, { formKey: 'scheduled-transactions', displayedTransactions: displayedPendingTransactions, initialValues: {
                                filterOption: config_1.TRANSACTION_FILTERS.ACCOUNT_NUMBER,
                            }, salesReports: salesReports, fetchError: salesReportError, allTransactions: scheduledPendingTransaction, handlePageChange: handlePendingTransactionPageChange, handleFilters: handlePendingTransactionFilter }) })] }), isActivityTableAccessible && ((0, jsx_runtime_1.jsxs)("div", { className: "dashboard__activities m-t-50 m-b-30", children: [(0, jsx_runtime_1.jsxs)("div", { className: "dashboard__activities--title", children: ["Application Activity (last ", config_1.REPORTS_START_OFFSET_DAYS, " days)"] }), (0, jsx_runtime_1.jsxs)("div", { className: "dashboard__activities--table m-t-30 ", children: [(0, jsx_runtime_1.jsx)(forms_1.ActivityFilterForm, { onSubmit: handleActivityFilter, initialValues: {
                                    applicationStatus: ['All'],
                                    startDate: startDate,
                                    endDate: endDate,
                                } }), !applicationReports && !applicationReportError ? ((0, jsx_runtime_1.jsx)(components_1.Spinner, {})) : ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: applicationReportError ? ((0, jsx_runtime_1.jsx)("div", { className: "empty-state-container", children: (0, jsx_runtime_1.jsx)("p", { className: "text-red", children: "Fetching applications failed, please refresh" }) })) : applications &&
                                    applications.length > 0 &&
                                    applicationStatus ? ((0, jsx_runtime_1.jsx)(merchant_portal_components_1.ApplicationActivityTable, { data: applications })) : ((0, jsx_runtime_1.jsx)("div", { className: "empty-state-container", children: (0, jsx_runtime_1.jsx)("p", { children: "No Applications" }) })) }))] })] }))] }));
}
const selector = (0, redux_form_1.formValueSelector)('activityFilterForm');
function mapStateToProps(state) {
    return {
        applicationStatus: selector(state, 'applicationStatus'),
        searchField: selector(state, 'searchField'),
        applicationReports: reducer_1.selectors.applicationReports(state),
        currentLocation: reducer_1.selectors.currentLocation(state),
        currentPermissions: reducer_1.selectors.currentPermissions(state),
        salesReports: reducer_1.selectors.salesReports(state),
        // allLocations: selectors.allLocations(state),
    };
}
const mapDispatchToProps = {
    flashErrorMessage: redux_flash_1.flashErrorMessage,
};
const mapApiAuthToProps = {
    getApplicationsReport: apiActions.getApplicationsReport,
    getSalesReport: apiActions.getSalesReport,
    fetchAllLocationsForManager: apiActions.fetchAllLocationsForManager,
    getProposedEnrollmentDataChanges: apiActions.getProposedEnrollmentDataChanges,
};
exports.default = (0, redux_1.compose)((0, react_redux_1.connect)(mapStateToProps, mapDispatchToProps), (0, utils_1.withApiAuth)(mapApiAuthToProps))(DashboardTables);
