"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("@coreui/react");
const react_2 = require("react");
const ModalBasic = ({ display, onClose, onCancel, onAgree, }) => {
    const [showModal, setShowModal] = (0, react_2.useState)(display.title || display.message ? true : false);
    const handleClose = () => {
        setShowModal(false);
        if (onClose)
            onClose();
    };
    const handleCancel = () => {
        if (onCancel)
            onCancel();
    };
    const handleAgree = () => {
        if (onAgree)
            onAgree();
    };
    (0, react_2.useEffect)(() => {
        if (display.title || display.message) {
            setShowModal(true);
        }
        else {
            setShowModal(false);
        }
    }, [display]);
    return ((0, jsx_runtime_1.jsxs)(react_1.CModal, { alignment: "center", visible: showModal, onClose: handleClose, portal: false, children: [(0, jsx_runtime_1.jsx)(react_1.CModalHeader, { children: (0, jsx_runtime_1.jsx)(react_1.CModalTitle, { children: display.title }) }), (0, jsx_runtime_1.jsx)(react_1.CModalBody, { children: (0, jsx_runtime_1.jsx)("p", { children: display.message }) }), (0, jsx_runtime_1.jsxs)(react_1.CModalFooter, { children: [(0, jsx_runtime_1.jsx)(react_1.CButton, { color: "primary", variant: "ghost", onClick: handleCancel, children: "Cancel" }), (0, jsx_runtime_1.jsx)(react_1.CButton, { color: "primary", onClick: handleAgree, children: "Okay" })] })] }));
};
exports.default = ModalBasic;
