"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const auth0_react_1 = require("@auth0/auth0-react");
const react_1 = require("react");
const components_1 = require("components");
const config_1 = require("config");
function NoManagerAccess() {
    const { logout, user } = (0, auth0_react_1.useAuth0)();
    const { email } = user || {};
    return ((0, jsx_runtime_1.jsx)("div", { className: "declined-container", children: (0, jsx_runtime_1.jsxs)("div", { className: "content", children: [(0, jsx_runtime_1.jsx)(components_1.H1, { className: "mar-y-20", children: "Account Has No Configured Locations" }), (0, jsx_runtime_1.jsxs)(components_1.P, { variant: "body-large", bold: true, className: "mar-y-20", children: ["The account, ", email, ", does not have access to any location."] }), (0, jsx_runtime_1.jsx)(components_1.P, { variant: "body-large", bold: true, className: "mar-y-20", children: "Please contact your local administrator to obtain access." }), (0, jsx_runtime_1.jsx)(components_1.Button, { type: "button", className: "button-warn-outline", onClick: () => logout({ returnTo: (0, config_1.makeApplicationURL)(config_1.PATH.MERCHANT_PORTAL) }), children: "Sign Out" })] }) }));
}
exports.default = (0, react_1.memo)(NoManagerAccess);
