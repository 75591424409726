"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_final_form_1 = require("react-final-form");
const components_1 = require("components");
const merchant_portal_components_1 = require("merchant-portal-components");
const utils_1 = require("utils");
function RefundRequestForm({ handleSubmit, handleCancel, lineItems, }) {
    const [showLTORefundModal, setShowLTORefundModal] = (0, react_1.useState)(false);
    const [LTOValues, setLTOValues] = (0, react_1.useState)(null);
    const validateSubmission = (values) => {
        const errors = {
            amount: '',
            correctAmountProvided: '',
        };
        if (!values.amount || values.amount === '0.00') {
            errors.amount = "Amount can't be blank";
            (0, utils_1.toastError)('Please review the information provided and correct all form errors');
            return errors;
        }
    };
    const beforeSubmit = async (values) => {
        const errors = validateSubmission(values);
        if (errors) {
            return errors;
        }
        const lineItemIds = lineItems
            ?.map((lineItem) => {
            if (values[`lineItem${lineItem.lineItemId}`]) {
                return lineItem.lineItemId;
            }
            return undefined;
        })
            .filter((lineItemId) => lineItemId !== undefined);
        await handleSubmit(isLTORefund && lineItemIds
            ? {
                ...values,
                amount: (0, utils_1.toAmount)(values.amount),
                lineItemIds,
            }
            : {
                ...values,
                amount: (0, utils_1.toAmount)(values.amount),
            });
    };
    const beforeSubmitLTO = async (values) => {
        const errors = validateSubmission(values);
        if (errors) {
            return errors;
        }
        setLTOValues(values);
        setShowLTORefundModal(true);
    };
    const MAX_AMOUNT_LENGTH = 18;
    const isLTORefund = lineItems && lineItems.length > 0 ? true : false;
    return ((0, jsx_runtime_1.jsxs)("div", { className: "start-application__form  m-t-50", children: [(0, jsx_runtime_1.jsx)(react_final_form_1.Form, { onSubmit: isLTORefund ? beforeSubmitLTO : beforeSubmit, render: ({ handleSubmit, submitting, values }) => ((0, jsx_runtime_1.jsxs)("form", { onSubmit: handleSubmit, children: [isLTORefund && lineItems ? ((0, jsx_runtime_1.jsx)(merchant_portal_components_1.RefundLineItems, { lineItems: lineItems })) : null, (0, jsx_runtime_1.jsxs)("div", { className: "refund-amount", children: [(0, jsx_runtime_1.jsx)(react_final_form_1.Field, { id: "amount", component: components_1.InputNumber, label: "Amount *", name: "amount", placeholder: "Total Amount of Sale", preicon: "$", allowLeadingZeros: false, thousandsGroupStyle: "thousand", thousandSeparator: ",", decimalScale: 2, isAllowed: (values) => {
                                        const { value } = values;
                                        return value.length < MAX_AMOUNT_LENGTH;
                                    }, disabled: isLTORefund }), (0, jsx_runtime_1.jsx)(react_final_form_1.FormSpy, { subscription: { values: true }, children: ({ values, form }) => {
                                        if (!isLTORefund)
                                            return null;
                                        let totalAmount = 0;
                                        const numberOfLineItems = lineItems?.length ?? 0;
                                        for (let i = 0; i < numberOfLineItems; i++) {
                                            const currentValueName = `lineItem${lineItems[i].lineItemId}`;
                                            if (Object.keys(values).length === 0) {
                                                return null;
                                            }
                                            if (values[currentValueName]) {
                                                totalAmount += Number(lineItems[i].amount);
                                            }
                                        }
                                        setTimeout(() => {
                                            //this is a workaround to solve a known console error with the library, as documented here: https://github.com/final-form/react-final-form/issues/809
                                            form.change('amount', String(totalAmount.toFixed(2)));
                                        }, 0);
                                        return null;
                                    } })] }), (0, jsx_runtime_1.jsx)("div", { children: (0, jsx_runtime_1.jsxs)("div", { className: "checkbox-container", children: [(0, jsx_runtime_1.jsx)(react_final_form_1.Field, { name: "correctAmountProvided", dataTestId: "correct-amount-provided", component: components_1.InputCheckbox, hideBreakTag: true, type: "checkbox", required: true }), (0, jsx_runtime_1.jsx)(components_1.Label, { htmlFor: "correctAmountProvided", size: "medium", children: "I confirm that this is the correct account and amount to be refunded." })] }) }), (0, jsx_runtime_1.jsxs)("div", { className: "start-application__form--actions m-t-50 m-b-50", children: [(0, jsx_runtime_1.jsx)("div", { className: "submit", children: (0, jsx_runtime_1.jsx)(components_1.Button, { disabled: submitting || !values.correctAmountProvided, submitting: submitting, className: `button-warn ${!values.correctAmountProvided ? `warn-disabled` : ``}`, type: "submit", children: "SUBMIT REFUND" }) }), (0, jsx_runtime_1.jsx)("div", { className: "cancel", children: (0, jsx_runtime_1.jsx)("div", { role: "presentation", onClick: () => handleCancel(), children: "CANCEL" }) })] })] })) }), isLTORefund && LTOValues ? ((0, jsx_runtime_1.jsx)(merchant_portal_components_1.LTORefundConfirmModal, { isOpen: showLTORefundModal, setIsOpen: setShowLTORefundModal, beforeSubmit: beforeSubmit, values: LTOValues })) : null] }));
}
exports.default = RefundRequestForm;
