"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("@coreui/react");
const react_2 = require("react");
const react_final_form_1 = require("react-final-form");
const react_toastify_1 = require("react-toastify");
const internal_portal_components_1 = require("internal-portal-components");
const internal_portal_hooks_1 = require("internal-portal-hooks");
const portal_api_1 = require("portal-api");
const fields_1 = require("./fields");
const func_1 = require("./func");
function EnrollmentForm({ initVals, unsetVals, locationId, }) {
    const formInfoRef = (0, react_2.useRef)(null);
    const formUploadsRef = (0, react_2.useRef)(null);
    // Hooks - UI
    const { Modal, AGREE, promiseModal } = (0, internal_portal_hooks_1.useModal)();
    // Hooks - API
    const [patchEnrollmentsStatus] = (0, portal_api_1.usePatchInternalPortalV1LocationsLocationIdEnrollmentsMerchantApplicationIdStatusMutation)();
    const [patchEnrollments] = (0, portal_api_1.usePatchInternalPortalV1LocationsLocationIdEnrollmentsMerchantApplicationIdMutation)();
    const formSubmit = async (values, formApi) => {
        const submitBtn = values.submitBtn;
        // Submitted Values
        if (values) {
            //do something useful
            const isFormValid = (0, func_1.formValidate)(values);
            // Is form valid
            if (isFormValid === undefined) {
                const merchAppId = values.id;
                // Save
                if (submitBtn === 'save') {
                    const resp = await handleSave({
                        merchAppId,
                        values,
                    });
                    if ('data' in resp) {
                        (0, react_toastify_1.toast)('Form Saved Successfully', {
                            type: 'success',
                        });
                        const tmpData = {
                            ...values,
                            ...resp.data,
                        };
                        unsetVals({
                            ...tmpData,
                            sameAddress: tmpData.sameAddress,
                            status: tmpData.status,
                        });
                        formApi.restart({ ...tmpData });
                    }
                    if ('error' in resp && 'data' in resp.error) {
                        const errorMessage = resp.error.data
                            .message;
                        (0, react_toastify_1.toast)(`Error saving enrollments: ${errorMessage}`, {
                            type: 'error',
                        });
                    }
                }
                // Update Status
                if (submitBtn === 'approve' || submitBtn === 'reject') {
                    let btnStatus;
                    switch (submitBtn) {
                        case 'approve':
                            btnStatus = 'review'; // Approve & Submit -> Review
                            break;
                        case 'reject':
                            btnStatus = 'disqualify'; // Reject -> Disqualify
                            break;
                        default:
                            btnStatus = null;
                    }
                    const resp = await handleStatus({
                        merchAppId,
                        status: btnStatus,
                    });
                    const formStrings = (0, func_1.createFormStrings)(btnStatus);
                    if (resp && 'error' in resp && 'data' in resp.error) {
                        const errorMessage = resp.error.data
                            .message;
                        (0, react_toastify_1.toast)(`${formStrings.errorMessage} ${errorMessage}`, {
                            type: 'error',
                        });
                    }
                    if (resp && 'data' in resp) {
                        const respData = Array.from(resp.data);
                        if (respData.length > 0) {
                            const { enrollmentApp, sameAddress, appStatus } = (0, func_1.formatEnrollmentData)(respData[0]);
                            unsetVals({
                                ...enrollmentApp,
                                sameAddress: sameAddress,
                                status: appStatus,
                            });
                            (0, react_toastify_1.toast)(`${formStrings.successMessage}`, {
                                type: 'success',
                            });
                        }
                    }
                }
            }
            else {
                (0, react_toastify_1.toast)(`Form has errors: ${JSON.stringify(isFormValid)}`, {
                    type: 'error',
                });
                // Form IS NOT VALID
                return isFormValid;
            }
        }
    };
    const handleSave = async ({ merchAppId, values, }) => {
        const tmpFields = {
            ...values,
            id: undefined,
            createdAt: undefined,
            attachments: undefined,
            sameAddress: undefined,
            submitBtn: undefined,
            status: undefined,
        };
        const tmpFormData = {
            ...tmpFields,
        };
        let snakeCaseData = {};
        Object.entries(tmpFormData).map((itm) => {
            snakeCaseData = {
                ...snakeCaseData,
                [(0, func_1.caseCamelToSnake)(itm[0])]: itm[1],
            };
        });
        const formData = {
            locationId: locationId,
            merchantApplicationId: merchAppId,
            body: {
                ...snakeCaseData,
            },
        };
        return patchEnrollments({
            ...formData,
        });
    };
    const handleStatus = async ({ merchAppId, status, }) => {
        const formStrings = (0, func_1.createFormStrings)(status);
        // Confirm Modal
        const btnResp = await promiseModal(formStrings.modalTitle, formStrings.modalMessage);
        if (btnResp === AGREE) {
            return await patchEnrollmentsStatus({
                locationId: locationId,
                merchantApplicationId: merchAppId,
                body: {
                    status: status /** Status of the merchant application */,
                },
            });
        }
    };
    const handleUploadCallback = (respData) => {
        unsetVals({
            ...initVals,
            ...respData,
        });
    };
    const checkToDisableFieldsets = (0, react_2.useCallback)(() => {
        const infoForm = formInfoRef.current;
        const uploadsForm = formUploadsRef.current;
        (0, func_1.disableFieldsets)(infoForm, Array.from(infoForm?.classList ?? []).includes('is-disabled'));
        (0, func_1.disableFieldsets)(uploadsForm, Array.from(uploadsForm?.classList ?? []).includes('is-disabled'));
    }, [formInfoRef, formUploadsRef]);
    (0, react_2.useEffect)(() => {
        checkToDisableFieldsets();
    }, [initVals]);
    return ((0, jsx_runtime_1.jsxs)("div", { className: "form-shell", children: [(0, jsx_runtime_1.jsx)("div", { className: "location-subcard", children: (0, jsx_runtime_1.jsxs)(react_1.CCard, { className: "location-box box-stack applicant-info-container", children: [(0, jsx_runtime_1.jsx)(react_final_form_1.Form, { initialValues: { ...initVals }, onSubmit: formSubmit, render: ({ handleSubmit, pristine, submitting, errors }) => {
                                const isDisabled = !(pristine || submitting) || initVals.status !== 'entered';
                                return ((0, jsx_runtime_1.jsxs)(react_1.CForm, { ref: formInfoRef, className: `${initVals.status !== 'entered'
                                        ? 'is-disabled'
                                        : 'not-disabled'}`, onSubmit: handleSubmit, children: [(0, jsx_runtime_1.jsx)("div", { children: errors ? ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: (0, jsx_runtime_1.jsx)("div", { children: errors && !!Object.entries(errors).length ? ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: JSON.stringify(errors) })) : null }) })) : null }), (0, jsx_runtime_1.jsx)(react_1.CCardTitle, { className: "w100", as: "h4", children: (0, jsx_runtime_1.jsxs)("div", { className: "flex-wrap-md row-gap-10-md space-between", children: [(0, jsx_runtime_1.jsx)("span", { children: " American First Finance" }), (0, jsx_runtime_1.jsx)(fields_1.FieldsButtons, { pristine: pristine, isLoading: submitting, isDisabled: isDisabled })] }) }), (0, jsx_runtime_1.jsxs)(react_1.CContainer, { fluid: true, className: "pad-0", children: [(0, jsx_runtime_1.jsx)("div", { className: "text-right", children: (0, jsx_runtime_1.jsxs)("span", { children: ["Application ID: \u00A0", initVals.id, "\u00A0\u00A0\u00A0|\u00A0\u00A0\u00A0 Status:\u00A0", (0, jsx_runtime_1.jsx)("small", { children: String(initVals.status)
                                                                    .toLocaleUpperCase()
                                                                    .replaceAll('_', ' ') })] }) }), (0, jsx_runtime_1.jsx)(internal_portal_components_1.FormSection, { title: "Practice Details", children: (0, jsx_runtime_1.jsx)(fields_1.FieldsPracticeDetails, {}) }), (0, jsx_runtime_1.jsx)(internal_portal_components_1.FormSection, { title: "Business Details", children: (0, jsx_runtime_1.jsx)(fields_1.FieldsBusinessDetails, {}) }), (0, jsx_runtime_1.jsx)(internal_portal_components_1.FormSection, { title: "Primary Contact's Details", children: (0, jsx_runtime_1.jsx)(fields_1.FieldsContactDetails, {}) }), (0, jsx_runtime_1.jsx)(internal_portal_components_1.FormSection, { title: "Business Address", children: (0, jsx_runtime_1.jsx)(fields_1.FieldsBusinessAddress, {}) }), (0, jsx_runtime_1.jsx)(internal_portal_components_1.FormSection, { children: (0, jsx_runtime_1.jsx)(fields_1.FieldsMailingAddress, {}) }), (0, jsx_runtime_1.jsx)(internal_portal_components_1.FormSection, { title: "Banking Details", children: (0, jsx_runtime_1.jsx)(fields_1.FieldsBankingDetails, {}) }), (0, jsx_runtime_1.jsx)(internal_portal_components_1.FormSection, { title: "License Verification", divider: false, children: (0, jsx_runtime_1.jsx)(fields_1.FieldsLicense, {}) }), (0, jsx_runtime_1.jsx)(internal_portal_components_1.FormSection, { title: "Attachments", children: (0, jsx_runtime_1.jsx)(fields_1.FieldsAttachments, {}) })] })] }));
                            } }), (0, jsx_runtime_1.jsx)(react_final_form_1.Form, { initialValues: { ...initVals }, onSubmit: () => { }, render: ({ pristine, submitting }) => {
                                const isDisabled = !(pristine || submitting) || initVals.status !== 'entered';
                                return ((0, jsx_runtime_1.jsx)(react_1.CForm, { encType: "multipart/form-data", method: "post", ref: formUploadsRef, className: `${isDisabled ? 'is-disabled' : 'not-disabled'}`, children: (0, jsx_runtime_1.jsx)(internal_portal_components_1.FormSection, { children: (0, jsx_runtime_1.jsx)(fields_1.FieldsUploads, { locationId: locationId, uploadCallback: handleUploadCallback }) }) }));
                            } })] }) }), (0, jsx_runtime_1.jsx)(Modal, {})] }));
}
exports.default = EnrollmentForm;
