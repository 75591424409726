"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.setShowDrawer = exports.setEnrollments = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const lodash_1 = require("lodash");
const initialState = {
    enrollments: [],
    showDrawer: false,
};
const slice = (0, toolkit_1.createSlice)({
    name: 'locations',
    initialState,
    reducers: {
        setEnrollments: (state, action) => {
            (0, lodash_1.set)(state, 'enrollments', action.payload);
        },
        setShowDrawer: (state, action) => {
            (0, lodash_1.set)(state, 'showDrawer', action.payload);
        },
    },
});
_a = slice.actions, exports.setEnrollments = _a.setEnrollments, exports.setShowDrawer = _a.setShowDrawer;
exports.default = slice.reducer;
