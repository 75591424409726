"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_final_form_1 = require("react-final-form");
const components_1 = require("components");
const config_1 = require("config");
const Error = ({ name }) => ((0, jsx_runtime_1.jsx)(react_final_form_1.Field, { name: name, subscription: { touched: true, error: true }, render: ({ meta: { touched, error, submitError } }) => (error || submitError) && touched ? ((0, jsx_runtime_1.jsx)("span", { className: "error-message", children: error || submitError })) : null }));
const FieldsAddress = () => {
    return ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: (0, jsx_runtime_1.jsxs)("div", { className: "", children: [(0, jsx_runtime_1.jsxs)("div", { className: "form-row align-start", children: [(0, jsx_runtime_1.jsx)(react_final_form_1.Field, { name: "address_spouse_line1", label: `Spouse's Street Address`, component: components_1.InputText, placeholder: "Enter street address", type: "text" }), (0, jsx_runtime_1.jsx)(react_final_form_1.Field, { name: "address_spouse_line2", label: `Spouse's Street Address Line 2 - Optional`, component: components_1.InputText, placeholder: "Enter street address line 2", type: "text" })] }), (0, jsx_runtime_1.jsxs)("div", { className: "form-row align-start", children: [(0, jsx_runtime_1.jsx)("div", { className: "w50", children: (0, jsx_runtime_1.jsx)(react_final_form_1.Field, { name: "address_spouse_city", label: `Spouse's City`, component: components_1.InputText, placeholder: "Enter city", type: "text" }) }), (0, jsx_runtime_1.jsxs)("div", { className: "w25", children: [(0, jsx_runtime_1.jsx)("label", { htmlFor: "address_spouse_state", children: "Spouse's State" }), (0, jsx_runtime_1.jsxs)(react_final_form_1.Field, { required: true, name: "address_spouse_state", component: "select", defaultValue: '', children: [(0, jsx_runtime_1.jsx)("option", { children: "Select state" }), config_1.STATES.map((itm, idx) => {
                                            return ((0, jsx_runtime_1.jsx)("option", { value: itm.value, children: itm.key }, `state-option-${itm.key}-${idx}`));
                                        })] }), (0, jsx_runtime_1.jsx)(Error, { name: "address_spouse_state" })] }), (0, jsx_runtime_1.jsx)("div", { className: "w25", children: (0, jsx_runtime_1.jsx)(react_final_form_1.Field, { name: "address_spouse_zip", label: `Spouse's Zip Code`, component: components_1.InputNumber, placeholder: "Enter zip code", type: "text", isAllowed: (values) => {
                                    const { floatValue, value, formattedValue } = values;
                                    return (value.length <= 5 ||
                                        (value === '' &&
                                            formattedValue === '' &&
                                            floatValue === undefined));
                                } }) })] })] }) }));
};
exports.default = FieldsAddress;
