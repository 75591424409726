"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formValidate = void 0;
const utils_1 = require("utils");
const formValidate = (values) => {
    if (values) {
        const errors = {};
        if (!values.primaryContactPhone) {
            errors.primaryContactPhone = 'Phone Number Required';
        }
        if (values.primaryContactPhone &&
            (0, utils_1.validatePhoneNumber)(values.primaryContactPhone)) {
            errors.primaryContactPhone = 'Invalid Phone Number';
        }
        if (Object.keys(errors).length) {
            return errors;
        }
        else {
            return undefined;
        }
    }
};
exports.formValidate = formValidate;
