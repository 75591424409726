"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatEnrollmentData = exports.compareAddress = void 0;
const compareAddress = (address1, address2) => {
    const address1String = JSON.stringify(address1);
    const address2String = JSON.stringify(address2);
    return address1String === address2String;
};
exports.compareAddress = compareAddress;
const formatEnrollmentData = (singleApp) => {
    const appStatus = singleApp.status;
    const enrollmentApp = {
        ...singleApp.merchantApplication,
    };
    const tmpBusinessAddress = {
        address: enrollmentApp.businessAddress,
        city: enrollmentApp.businessCity,
        state: enrollmentApp.businessState,
        zip: enrollmentApp.businessZip,
    };
    const tmpMailingAddress = {
        address: enrollmentApp.mailingAddress,
        city: enrollmentApp.mailingCity,
        state: enrollmentApp.mailingState,
        zip: enrollmentApp.mailingZip,
    };
    const sameAddress = (0, exports.compareAddress)(tmpBusinessAddress, tmpMailingAddress);
    return {
        sameAddress,
        enrollmentApp,
        appStatus,
    };
};
exports.formatEnrollmentData = formatEnrollmentData;
