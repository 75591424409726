"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const lp_redux_api_1 = require("lp-redux-api");
const react_1 = require("react");
const react_redux_1 = require("react-redux");
const react_router_dom_1 = require("react-router-dom");
const redux_1 = require("redux");
const redux_flash_1 = require("redux-flash");
const apiActions = __importStar(require("api-actions"));
const components_1 = require("components");
const config_1 = require("config");
const merchant_management_v1_api_1 = require("merchant-management-v1-api");
const merchant_portal_components_1 = require("merchant-portal-components");
const utils_1 = require("utils");
const reducer_1 = require("../../reducer");
const components_2 = require("../components");
const forms_1 = require("../forms");
function LoanAdjustment({ currentLocation, loading, adjustLoan, cancelLoan, flashSuccessMessage, }) {
    const { locationId } = currentLocation;
    const location = (0, react_router_dom_1.useLocation)();
    const history = (0, react_router_dom_1.useHistory)();
    const [showAdjustConfirm, setShowAdjustConfirm] = (0, react_1.useState)(false);
    const [showCancelConfirm, setShowCancelConfirm] = (0, react_1.useState)(false);
    const [adjustmentData, setAdjustmentData] = (0, react_1.useState)(null);
    const { data: accountDetail } = (0, merchant_management_v1_api_1.useGetMerchantManagementV1LookupsAccountsAlphaeonAccountNumberQuery)({
        alphaeonAccountNumber: location.state.id,
        locationId: String(locationId),
    });
    if (!accountDetail) {
        return (0, jsx_runtime_1.jsx)(components_1.Spinner, {});
    }
    const { alphaeonAccountNumber } = accountDetail;
    const handleSubmit = (data) => {
        setAdjustmentData(data);
        setShowAdjustConfirm(true);
    };
    const handleLoanAdjustment = () => {
        return adjustLoan(alphaeonAccountNumber, { ...adjustmentData }, locationId);
    };
    const handleLoanCancel = () => {
        return cancelLoan(alphaeonAccountNumber, locationId);
    };
    const handleLoanCancelSuccess = () => {
        flashSuccessMessage('Loan Cancelled.');
        history.push(`${config_1.ACCOUNT_DETAIL_URL}${alphaeonAccountNumber}`);
    };
    const handleCancel = () => {
        setShowCancelConfirm(true);
    };
    const handleSubmitSuccess = () => {
        flashSuccessMessage('Loan Adjusted Successfully.');
        history.push(`${config_1.ACCOUNT_DETAIL_URL}${alphaeonAccountNumber}`);
    };
    return ((0, jsx_runtime_1.jsxs)("div", { className: "account-lookup", children: [(0, jsx_runtime_1.jsx)(components_1.H1, { variant: "headline-large", children: "Loan Adjustment" }), (0, jsx_runtime_1.jsx)("div", { className: "transaction-summary summary w-80 m-b-40 ", children: (0, jsx_runtime_1.jsx)(merchant_portal_components_1.LoanCard, { transaction: location.state.transaction, accountDetail: accountDetail, withCancel: true, handleCancelOrRefund: handleCancel }) }), (0, jsx_runtime_1.jsx)(components_1.H2, { variant: "headline-small", className: "edit-information", children: (0, jsx_runtime_1.jsxs)(components_1.Span, { variant: "headline-small", children: ["Edit Information", ' ', (0, jsx_runtime_1.jsx)(components_1.Span, { variant: "body-large", bold: true, children: "(You can enter information to one or both of the fields below)" })] }) }), (0, jsx_runtime_1.jsx)(forms_1.LoanAdjustmentForm, { alphaeonAccountNumber: alphaeonAccountNumber, permissions: 'currentPermissions', onSubmit: handleSubmit, onSubmitFail: utils_1.flashSubmitFailure, handleCancelEdit: () => history.push(`${config_1.ACCOUNT_DETAIL_URL}${alphaeonAccountNumber}`) }), loading && (0, jsx_runtime_1.jsx)(components_1.Spinner, {}), showAdjustConfirm && ((0, jsx_runtime_1.jsx)(components_2.LoanAdjustConfirmationModal, { adjustmentData: adjustmentData, accountDetail: accountDetail, handleConfirm: handleLoanAdjustment, handleSubmitSuccess: handleSubmitSuccess, handleSubmitFailure: utils_1.flashSubmitFailure, handleClose: () => setShowAdjustConfirm(false) })), showCancelConfirm && ((0, jsx_runtime_1.jsx)(components_2.LoanCancelConfirmationModal, { handleConfirm: handleLoanCancel, handleSubmitSuccess: handleLoanCancelSuccess, handleSubmitFailure: utils_1.flashSubmitFailure, handleClose: () => setShowCancelConfirm(false) }))] }));
}
function mapStateToProps(state) {
    return {
        currentLocation: reducer_1.selectors.currentLocation(state),
        loading: lp_redux_api_1.selectors.isLoading(state, 'ACCOUNT_DATA'),
    };
}
const mapDispatchToProps = {
    flashSuccessMessage: redux_flash_1.flashSuccessMessage,
};
const mapApiAuthToProps = {
    adjustLoan: apiActions.adjustLoan,
    cancelLoan: apiActions.cancelLoan,
};
exports.default = (0, redux_1.compose)((0, react_redux_1.connect)(mapStateToProps, mapDispatchToProps), (0, utils_1.withApiAuth)(mapApiAuthToProps))(LoanAdjustment);
