"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("@coreui/react");
const react_2 = require("react");
const react_final_form_1 = require("react-final-form");
const FieldsButtons = ({ pristine, isLoading, isDisabled, }) => {
    const form = (0, react_final_form_1.useForm)();
    const formState = (0, react_final_form_1.useFormState)();
    const submitBtn = formState.values['submitBtn'];
    const [isSubmitBtn, setIsSubmitButton] = (0, react_2.useState)(submitBtn);
    (0, react_2.useEffect)(() => {
        setIsSubmitButton(isSubmitBtn);
    }, [submitBtn]);
    return ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: (0, jsx_runtime_1.jsxs)("div", { className: "flex space-between col-gap-10", children: [!pristine && ((0, jsx_runtime_1.jsx)(react_1.CButton, { type: "button", color: "dark", disabled: isLoading, "aria-disabled": isLoading, onClick: () => {
                        form.restart();
                    }, children: "Cancel Changes" })), !pristine && ((0, jsx_runtime_1.jsxs)(react_1.CButton, { onClick: () => form.change('submitBtn', 'save'), type: "submit", color: "primary", variant: "outline", disabled: isLoading, "aria-disabled": isLoading, name: "btnSave", children: [isLoading && ((0, jsx_runtime_1.jsx)(react_1.CSpinner, { as: "span", size: "sm", variant: "grow", "aria-hidden": "true" })), "\u00A0 Save Changes"] })), pristine && ((0, jsx_runtime_1.jsxs)(react_1.CButton, { onClick: () => form.change('submitBtn', 'reject'), as: "button", type: "submit", color: isDisabled ? 'secondary' : 'danger', variant: "outline", disabled: !pristine || isDisabled, "aria-disabled": !pristine || isDisabled, children: [isLoading && submitBtn === 'reject' && ((0, jsx_runtime_1.jsx)(react_1.CSpinner, { as: "span", size: "sm", variant: "grow", "aria-hidden": "true" })), "\u00A0 Reject Application"] })), pristine && ((0, jsx_runtime_1.jsxs)(react_1.CButton, { onClick: () => form.change('submitBtn', 'approve'), as: "button", type: "submit", color: isDisabled ? 'secondary' : 'primary', disabled: !pristine || isDisabled, "aria-disabled": !pristine || isDisabled, children: [isLoading && submitBtn === 'approve' && ((0, jsx_runtime_1.jsx)(react_1.CSpinner, { as: "span", size: "sm", variant: "grow", "aria-hidden": "true" })), "\u00A0 Approve & Submit"] }))] }) }));
};
exports.default = FieldsButtons;
