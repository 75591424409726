"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.usePostAbTestsV1ParticipantsAssignVariantTestSlugMutation = exports.abTestsApi = void 0;
const abTestsApiSlice_1 = require("../abTestsApiSlice");
const injectedRtkApi = abTestsApiSlice_1.api.injectEndpoints({
    endpoints: (build) => ({
        postAbTestsV1ParticipantsAssignVariantTestSlug: build.mutation({
            query: (queryArg) => ({
                url: `/ab_tests/v1/participants/assign_variant/${queryArg.testSlug}`,
                method: 'POST',
                body: queryArg.body,
                headers: {
                    Authorization: queryArg.authorization,
                },
            }),
        }),
    }),
    overrideExisting: false,
});
exports.abTestsApi = injectedRtkApi;
exports.usePostAbTestsV1ParticipantsAssignVariantTestSlugMutation = injectedRtkApi.usePostAbTestsV1ParticipantsAssignVariantTestSlugMutation;
