"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.disableFieldsets = void 0;
const disableFieldsets = (formRef, disableBool) => {
    if (formRef) {
        const fieldsets = Array.from(formRef.querySelectorAll('fieldset'));
        Array.from(fieldsets).map((itm) => {
            itm.disabled = disableBool;
        });
    }
};
exports.disableFieldsets = disableFieldsets;
