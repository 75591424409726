"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const date_fns_1 = require("date-fns");
const react_1 = require("react");
const react_final_form_1 = require("react-final-form");
const components_1 = require("components");
function FieldsPaymentFrequency({ values }, ref) {
    const TODAY = new Date();
    const FROM_TODAY_MIN = (0, date_fns_1.subDays)(new Date(), 90);
    const FROM_TODAY_MAX = (0, date_fns_1.addDays)(new Date(), 90);
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)("div", { className: "section-header", ref: ref, children: "Payment Frequency" }), (0, jsx_runtime_1.jsxs)("div", { className: "payment-frequency-body", children: [(0, jsx_runtime_1.jsxs)("div", { className: "row", children: [(0, jsx_runtime_1.jsxs)("div", { className: "field-container", children: [(0, jsx_runtime_1.jsx)(components_1.Label, { htmlFor: "income_frequency", size: "medium", children: "Payment Frequency *" }), (0, jsx_runtime_1.jsxs)(react_final_form_1.Field, { name: "income_frequency", component: "select", required: true, defaultValue: '', children: [(0, jsx_runtime_1.jsx)("option", { disabled: true, value: "", children: "Select a value" }), (0, jsx_runtime_1.jsx)("option", { value: "W", children: "Weekly" }), (0, jsx_runtime_1.jsx)("option", { value: "B", children: "Bi-Weekly" }), (0, jsx_runtime_1.jsx)("option", { value: "M", children: "Monthly" }), (0, jsx_runtime_1.jsx)("option", { value: "T", children: "Twice Monthly" })] })] }), values.income_frequency && ((0, jsx_runtime_1.jsx)("div", { className: "field-container", children: (0, jsx_runtime_1.jsx)(react_final_form_1.Field, { placeholder: "mm/dd/yyyy", name: "income_pay_next", labelText: "Next Pay Date", component: components_1.DatePicker, minDate: TODAY, maxDate: FROM_TODAY_MAX }) }))] }), values.income_frequency === 'T' && ((0, jsx_runtime_1.jsx)("div", { className: "row", children: (0, jsx_runtime_1.jsx)("div", { className: "field-container", children: (0, jsx_runtime_1.jsx)(react_final_form_1.Field, { placeholder: "mm/dd/yyyy", name: "income_pay_last", labelText: "Last Pay Date", component: components_1.DatePicker, minDate: FROM_TODAY_MIN, maxDate: TODAY }) }) }))] })] }));
}
exports.default = (0, react_1.forwardRef)(FieldsPaymentFrequency);
