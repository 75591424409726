"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const assignProveAbVariant = async (cookies, assignProveVariant, setCookie, testSlug, channel, locationId) => {
    const SEVEN_DAYS_FROM_NOW = new Date(Date.now() + 7 * 24 * 60 * 60 * 1000);
    if (channel === 'OTA') {
        return 'control';
    }
    if (testSlug === 'consumer_prove_no_store_test') {
        if (!cookies.proveAb) {
            try {
                const data = await assignProveVariant({
                    testSlug: testSlug,
                    body: {},
                }).unwrap();
                setCookie('proveAb', data, {
                    expires: SEVEN_DAYS_FROM_NOW,
                });
                if (data.variant.name === 'treatment') {
                    return 'treatment';
                }
                return 'control';
            }
            catch (error) {
                setCookie('proveAb', { variant: { name: 'control' } }, {
                    expires: SEVEN_DAYS_FROM_NOW,
                });
                return 'control';
            }
        }
        else {
            return cookies.proveAb.variant.name;
        }
    }
    else if (testSlug === 'consumer_prove_yes_store_test') {
        if (!cookies.proveStoreAb ||
            cookies.proveStoreAb.locationId !== locationId) {
            try {
                const data = await assignProveVariant({
                    testSlug: testSlug,
                    body: {
                        locationId,
                    },
                }).unwrap();
                setCookie('proveStoreAb', { ...data, locationId }, {
                    expires: SEVEN_DAYS_FROM_NOW,
                });
                if (data.variant.name === 'treatment') {
                    return 'treatment';
                }
                return 'control';
            }
            catch (error) {
                setCookie('proveStoreAb', { variant: { name: 'control' } }, {
                    expires: SEVEN_DAYS_FROM_NOW,
                });
                return 'control';
            }
        }
        else {
            return cookies.proveStoreAb.variant.name;
        }
    }
};
exports.default = assignProveAbVariant;
