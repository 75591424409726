"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const date_fns_1 = require("date-fns");
const react_final_form_1 = require("react-final-form");
const components_1 = require("components");
const FieldInputDate = ({ maxDays = 9000, // 24 years
minDays = new Date(), // 1 year
label = 'Expiration Date', name = 'expirationDate', placeholder = 'mm/dd/yyyy', }) => {
    // Date Constants
    const TODAY = minDays;
    const FROM_TODAY_MAX = (0, date_fns_1.addDays)(new Date(), maxDays); // 24 years
    const DATE_NOW = {
        month: TODAY.getMonth(),
        day: TODAY.getDate(),
        year: TODAY.getFullYear(),
    };
    const DATE_MAX = {
        month: FROM_TODAY_MAX.getMonth(),
        day: FROM_TODAY_MAX.getDate(),
        year: FROM_TODAY_MAX.getFullYear(),
    };
    return ((0, jsx_runtime_1.jsx)(react_final_form_1.Field, { altclass: "coreUI", placeholder: placeholder, name: name, label: label, component: components_1.InputDateMask, type: "tel", mask: Date, autofix: "pad", min: new Date(DATE_NOW.year, DATE_NOW.month, DATE_NOW.day), max: new Date(DATE_MAX.year, DATE_MAX.month, DATE_MAX.day), lazy: true, unmask: false }));
};
exports.default = FieldInputDate;
