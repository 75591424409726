"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const lp_components_1 = require("lp-components");
const lp_form_1 = require("lp-form");
const react_1 = require("react");
const react_redux_1 = require("react-redux");
const redux_1 = require("redux");
const redux_form_1 = require("redux-form");
const components_1 = require("components");
const utils_1 = require("utils");
function LocationSelectorForm({ allLocations, handleSubmit, defaultValue, }) {
    const locationData = (0, react_1.useMemo)(() => (0, utils_1.optionsFromLocations)(allLocations), [allLocations]);
    return ((0, jsx_runtime_1.jsx)("div", { className: "start-application__form select-location-modal", children: (0, jsx_runtime_1.jsxs)("form", { noValidate: true, onSubmit: handleSubmit, children: [(0, jsx_runtime_1.jsx)(components_1.H1, { className: "m-b-50", children: "Select Location" }), (0, jsx_runtime_1.jsx)(redux_form_1.Field, { name: "selectedLocationIndex", component: components_1.Select, initialValue: defaultValue, label: "Location", required: true, options: locationData }), (0, jsx_runtime_1.jsx)("div", { className: "start-application__form--actions m-t-50 m-b-50", children: (0, jsx_runtime_1.jsx)("div", { className: "submit", children: (0, jsx_runtime_1.jsx)(lp_components_1.SubmitButton, { children: "Select Location" }) }) })] }) }));
}
function mapStateToProps() {
    return {};
}
const mapDispatchToProps = {};
exports.default = (0, redux_1.compose)((0, lp_form_1.lpForm)({
    name: 'locationSelectorForm',
    enableReinitialize: true,
    constraints: {
        selectedLocationIndex: { presence: true },
    },
}), (0, redux_form_1.formValues)({
    selectedLocationIndex: 'selectedLocationIndex',
}), (0, react_redux_1.connect)(mapStateToProps, mapDispatchToProps))(LocationSelectorForm);
